import { FaEnvelope, FaTiktok } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-600">
          {/* Social links */}
          <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
            <li className="ml-4">
              <a
                href="https://www.facebook.com/emmanuelmayball22"
                className="flex justify-center items-center  p-1 text-gray-700 hover:text-gray-900 bg-white hover:bg-purple-400 rounded-full shadow transition duration-150 ease-in-out"
                aria-label="Facebook"
              >
                <svg
                  className="w-8 h-8 fill-current"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                </svg>
              </a>
            </li>
            <li className="ml-4">
              <a
                href="https://www.instagram.com/emmanuelmayball/"
                className="flex justify-center items-center  p-1 text-gray-700 hover:text-purple-900 bg-white hover:bg-purple-400 rounded-full shadow transition duration-150 ease-in-out"
                aria-label="Instagram"
              >
                <svg
                  className="w-8 h-8 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" />
                </svg>
              </a>
            </li>
            <li className="ml-4">
              <a
                href="https://www.tiktok.com/@emmamayweek"
                className="flex justify-center items-center p-1 text-gray-700 hover:text-purple-900 bg-white hover:bg-purple-400 rounded-full shadow transition duration-150 ease-in-out"
                aria-label="Instagram"
              >
                <FaTiktok className="w-8 h-8 p-2 fill-current" />
              </a>
            </li>
            <li className="ml-4">
              <a
                href="mailto:presidents.emb84@gmail.com"
                className="flex justify-center items-center  p-1 text-gray-700 hover:text-purple-900 bg-white hover:bg-purple-400 rounded-full shadow transition duration-150 ease-in-out"
                aria-label="Instagram"
              >
                <FaEnvelope className="w-8 h-8 p-2 fill-current" />
              </a>
            </li>
          </ul>

          {/* Copyrights note */}
          <div className="text-sm text-white mr-4">
            All rights reserved. Web design by{" "}
            <a href="https://souradip.com" className="underline">
              souradip
            </a>
            .
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
