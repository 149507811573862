import React from "react";
import Contact from "./Contact";

function SignIn() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      <main className="flex-grow" data-aos="zoom-y-out" data-aos-delay="150">
        <section className="bg-lines" style={{ background: "black" }}>
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <a href="#committee" name="committee"></a>
              {/* Page header bg-gradient-to-b from-yellow-600 to-yellow-800 */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1 text-white">Our Committee</h1>
              </div>

              {/* Responsive grid of faces */}
              <div className="flex flex-wrap justify-center text-white">
                <Contact
                  name="Ebun Bello & Zoe Gunasekera"
                  role="Presidents"
                  email="presidents.emb84@gmail.com"
                />
                <Contact
                  name="Adelaide Fasham"
                  role="Ticketing"
                  email="ticketing.emb84@gmail.com"
                />
                <Contact
                  name="Max Duckworth & Harry Appleby-Taylor"
                  role="Infrastructure & Logistics Officers"
                  email="infrastructure.emb84@gmail.com"
                />

                <Contact
                  name="Celeste Crosbie"
                  role="Treasurer"
                  email="treasurer.emb84@gmail.com"
                />
                <Contact
                  name="Charlie Baar & Fin Brickman"
                  role="Food Officers"
                  email="food.emb84@gmail.com"
                />

                <Contact
                  name="Kell Johnston & Molly Macleod"
                  role="Drinks"
                  email="drinks.emb84@gmail.com"
                />

                <Contact
                  name="Kalina Stoyanova & Kirsti Jones"
                  role="Decor Officer"
                  email="decor.emb84@gmail.com"
                />

                <Contact
                  name="Daniel Kilburn"
                  role="Publicity and Sponsorship Officer"
                  email="publicity.emb84@gmail.com"
                />

                <Contact
                  name="Norah Rouffaert & Lewis Clark "
                  role="Music Officer"
                  email="Music.emb84@gmail.com"
                />

                <Contact
                  name="Lucy Randall & Kaycee Barwell"
                  role="Non-Musical Entertainment Officers"
                  email="entertainment.emb84@gmail.com"
                />

                <Contact
                  name="Harry Mitchell & Freya Matthews"
                  role="Staffing Officers"
                  email="staffing.emb84@gmail.com"
                />

                <Contact
                  name="Tom Moran"
                  role="Security Officer"
                  email="security.emb84@gmail.com"
                />

                <Contact
                  name="Anna Hawkins"
                  role="Access and Sustainability Officer"
                  email="sustainability.emb84@gmail.com"
                  email2="access.emb84@gmail.com"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default SignIn;
