import React, { useState, useEffect } from "react";
import Count from "./Count";

const Countdown = () => {
  const initTimeLeft = () => {
    const countTime = +new Date(`2/19/2025 22:00`) - +new Date();
    return {
      days: Math.floor(countTime / (1000 * 60 * 60 * 24)),
      hours: Math.floor((countTime / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((countTime / 1000 / 60) % 60),
      seconds: Math.floor((countTime / 1000) % 60),
    };
  };

  const [timeLeft, setTimeLeft] = useState(initTimeLeft());
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(initTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <>
      <Count time="DAYS" count={timeLeft.days} />
      <Count time="HOURS" count={timeLeft.hours} />
      <Count time="MINS" count={timeLeft.minutes} />
      <Count time="SECS" count={timeLeft.seconds} />
    </>
  );
};

export default Countdown;
