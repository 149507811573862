import React, { useEffect } from "react";
import Header from "./Header";

import { ReactComponent as Gramophone } from "./emma.svg";
import { ReactComponent as Announce } from "./emmaannounce.svg";
import { ReactComponent as AnnounceBig } from "./emmaannounce2_2.svg";

import "add-to-calendar-button/assets/css/atcb.css";

import {
  FaCalendarAlt,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaTiktok,
} from "react-icons/fa";
import { atcb_action } from "add-to-calendar-button";

import Committee from "./Committee";

import AOS from "aos";
import "aos/dist/aos.css";
import ReleaseTimeline from "./ReleaseTimeline";
import TicketTypes from "./TicketTypes";

import { Modal, Button } from "react-bootstrap";

function getScrollPercent() {
  var h = document.documentElement,
    b = document.body,
    st = "scrollTop",
    sh = "scrollHeight";
  return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
}
// function component
export default () => {
  const [isMobile, setIsMobile] = React.useState(false);
  const [videoModalOpen, setVideoModalOpen] = React.useState(false);

  useEffect(() => {
    const resiz = () => {
      setIsMobile(window.innerWidth < 768);
    };
    resiz();
    window.addEventListener("resize", resiz);
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    var tl = window.anime
      .timeline({ autoplay: true })

      .add({
        targets: "#c1",
        opacity: [1, 0.5],
        left: [0, 500],
        easing: "easeInOutExpo",
        duration: 700,
      })
      .add({
        targets: "#c2",
        opacity: [1, 0.5],
        right: [0, 500],
        easing: "easeInOutExpo",
        duration: 700,
      });
    window.addEventListener("scroll", () => {
      const persentage = getScrollPercent();
      tl.seek(tl.duration * (1 - persentage * 0.1));
    });

    const main = window.gsap
      .timeline({
        defaults: { duration: 1 },
        scrollTrigger: {
          trigger: ".spinner",
          scrub: true,
          start: "top center",
          start: "-=1000",
          end: "bottom center",
          end: "+=1500", // end after scrolling 500px beyond the start
        },
      })
      // .fromTo("#c1", {left: 0}, {left: "30vw", ease: "linear"})
      // .fromTo("#c2", {right: 0}, {right: "30vw", ease: "linear"})
      .fromTo(".calbut", { opacity: 0 }, { duration: 5, opacity: 1 })
      .fromTo(
        ".staffs",
        { opacity: 1 },
        { duration: 5, opacity: 0, ease: "power2.inOut" }
      )
      .fromTo(
        ".noot-2",
        { opacity: 1, rotate: 0, y: 0 },
        { duration: 3, rotate: 180, y: 200, opacity: 0, ease: "power2.inOut" }
      )
      .fromTo(
        ".noot-1",
        { scale: 0, x: 0, opacity: 0 },
        { x: -100, opacity: 1, duration: 3, scale: 1.2, stagger: 5 }
      )
      .fromTo(
        "footer",
        { opacity: 1 },
        { duration: 25, opacity: 1, ease: "power2.inOut" }
      );
  }, [isMobile]);

  return (
    <div>
      <Header />
      <div className="record" style={{ overflowX: "hidden", minHeight: 741 }}>
        <div
          className={`container mx-auto ${isMobile ? "" : "block"}`}
          style={{
            maxWidth: "10000px",
          }}
        >
          <div className="cont">
            <div
              style={{
                position: "relative",
                minHeight: isMobile ? 741 : 0,
              }}
            >
              <div style={{ width: "100%" }}>
                <div
                  id="announce"
                  style={{
                    transformOrigin: "50% 70%",
                    position: "relative",
                    marginTop: 200,
                    zIndex: 1,
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "0 auto",
                      maxWidth: "100%",
                      width: "800px",
                    }}
                  >
                    <img
                      src="/title.webp"
                      className={"dreamscape"}
                      style={{ display: "inline-block" }}
                    />
                    <div style={{ display: "flex" }}>
                      <img
                        src="/collegename.webp"
                        style={{
                          maxWidth: "20%",
                          width: "200px",
                          display: "inline-block",
                        }}
                      />
                      <div style={{ flex: 1 }} />
                      <img
                        src="/balldate.webp"
                        style={{
                          maxWidth: "20%",
                          width: "200px",
                          display: "inline-block",
                        }}
                      />
                    </div>
                    <img
                      src="/chapel.webp"
                      width={"600px"}
                      height={"848px"}
                      style={{ display: "inline-block", margin: "0 auto" }}
                    />
                  </div>
                </div>
                <img
                  src="/cloud1.webp"
                  id="c1"
                  width={"600px"}
                  style={{ position: "relative", top: "-500px" }}
                />
                <img
                  src="/cloud2.webp"
                  id="c2"
                  width={"600px"}
                  style={{
                    position: "relative",
                    top: "-800px",
                    float: "right",
                  }}
                />
              </div>

              <div class="spinner scroll-down">
                {" "}
                <a class="animate"></a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="scrolltrigger">
        <div>
          <div
            className="calbut"
            id="calbut"
            style={{ maxWidth: "800px", padding: 30, borderRadius: 20 }}
          >
            <div
              className="max-w-3xl mx-auto text-center pb-12 md:pb-16"
              id="headerr"
            >
              <h1
                className="h2 mb-4 text-center"
                style={{ fontFamily: "Base Neue !important" }}
              >
                <br />
                EMMA JUNE EVENT 2025
                <br />
                <span style={{ fontSize: "80%", letterSpacing: "10px" }}>
                  COURTING FATE
                </span>
              </h1>
              <p className="text-xl text-gray-800">
                <p>A night of suits and symbols…</p>
                <div class="muzieknootjes">
                  <div class="noot-1">&#9835; &#9833;</div>
                  <div class="noot-1">&#9833;</div>
                  <div class="noot-1">&#9839; &#9834;</div>
                  <div class="noot-1">&#9834;</div>
                </div>
                <p>
                  From sunset until sunrise this year’s Emmanuel June Event will
                  be the stuff that dreams are made of. Join us from 9pm until
                  6am… and make your dreams come true!
                </p>
              </p>
            </div>

            <br />
            <br />
            <a
              href="javascript:void"
              onClick={() => {
                atcb_action({
                  name: "Emma June Event 2025",
                  description: "",
                  startDate: "2024-06-16",
                  endDate: "2024-06-17",
                  startTime: "21:00",
                  endTime: "06:00",
                  location: "Emmanuel College, Cambridge, UK",
                  options: [
                    "Apple",
                    "Google",
                    "iCal",
                    "Microsoft365",
                    "Outlook.com",
                    "Yahoo",
                  ],
                  timeZone: "Europe/London",
                  iCalFileName: "Emma-May-Week-Event",
                });
              }}
              className=" link  atcb-button"
              style={{ margin: "0 auto" }}
            >
              <div>
                <FaCalendarAlt />
                <br />
                ADD TO CALENDAR
              </div>
            </a>
            <br />
            <br />
          </div>
        </div>
      </div>
      <br />
      <br />

      <div>
        <div
          id="vidcont"
          className="relative flex justify-center mb-8"
          style={{ zIndex: 3, position: "relative" }}
        >
          <div className="flex flex-col justify-center">
            <img
              className="mx-auto"
              src="/dreamscape_cover.jpg"
              width="768"
              height="432"
              alt="Hero"
              style={{ width: "100vw", objectFit: "cover", height: "500px" }}
            />
          </div>
          <button
            className="absolute top-full flex items-center transform -translate-y-1/2 hover:text-yellow-600 bg-gray-100 rounded-full font-medium group p-4 shadow-lg text-black"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setVideoModalOpen(true);
            }}
            aria-controls="modal"
          >
            <svg
              className="w-6 h-6 fill-current text-gray-900 group-hover:text-yellow-600 flex-shrink-0"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
              <path d="M10 17l6-5-6-5z" />
            </svg>
            <span className="ml-3">
              Watch the full promotional video for Emma June Event (3 min)
            </span>
          </button>
        </div>

        <Modal
          id="modal"
          ariaLabel="modal-headline"
          show={videoModalOpen}
          handleClose={() => setVideoModalOpen(false)}
        >
          <div className="relative pb-9/16">
            <iframe
              className="absolute w-full h-full"
              src={process.env.REACT_APP_VIDEO}
              title="Video"
              allowFullScreen
            ></iframe>
          </div>

          <Modal.Footer>
            <Button
              variant=""
              onClick={() => {
                setVideoModalOpen(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <br />
      <br />

      <ReleaseTimeline />

      <TicketTypes />

      <Committee />
      {/*
      <div className="flex flex-col overflow-hidden">
        <main className="flex-grow" data-aos="zoom-y-out" data-aos-delay="150">
  
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                
        <div className="md:grid md:grid-cols-2 md:gap-4">
          <div><img src="/mwa.jpg" width="100%" /></div>
          <div><h2 className="h2">🎉 Enrich your May Week with MWA! 🎉</h2>
<p>
We are excited to be partnering with May Week Alternative, one of Cambridge’s biggest student-run charity movements combining a positive philosophy for effective giving with celebration.<br/><br />They encourage students to give an amount personally significant to them to charity - they recommend the Against Malaria Foundation as it is independently rated as one of the most effective charities in the world.<br /><br />All donations are then match-funded to double your impact, and the MWA community comes together to celebrate their collective impact at their summer party during May Week!
<br /><br /></p><p>
Joining MWA is a great way to embrace charitable giving as a meaningful part of your May Week. Find out more, donate and join at mayweekalternative.org.uk by 23:59 on Sunday 5 March!
<br /></p>
</div>

          </div>
        </div>
      </div>
      </main>
    </div>*/}

      <footer className="">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-600">
            {/* Social links */}
            <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
              <li className="ml-4">
                <a
                  href="https://www.facebook.com/emmanuelmayball22"
                  className="flex justify-center items-center text-gray-700 hover:text-gray-900 bg-purple-300 hover:bg-purple-400 rounded-full shadow transition duration-150 ease-in-out"
                  aria-label="Facebook"
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                  </svg>
                </a>
              </li>
              <li className="ml-4">
                <a
                  href="https://www.instagram.com/emmanuelmayball/"
                  className="flex justify-center items-center text-gray-700 hover:text-purple-900 bg-purple-300 hover:bg-purple-400 rounded-full shadow transition duration-150 ease-in-out"
                  aria-label="Instagram"
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" />
                  </svg>
                </a>
              </li>
              <li className="ml-4">
                <a
                  href="https://www.tiktok.com/@emmamayweek"
                  className="flex justify-center items-center text-gray-700 hover:text-purple-900 bg-purple-300 hover:bg-purple-400 rounded-full shadow transition duration-150 ease-in-out"
                  aria-label="Instagram"
                >
                  <FaTiktok className="w-8 h-8 p-2 fill-current" />
                </a>
              </li>
              <li className="ml-4">
                <a
                  href="mailto:presidents.emb84@gmail.com"
                  className="flex justify-center items-center text-gray-700 hover:text-purple-900 bg-purple-300 hover:bg-purple-400 rounded-full shadow transition duration-150 ease-in-out"
                  aria-label="Instagram"
                >
                  <FaEnvelope className="w-8 h-8 p-2 fill-current" />
                </a>
              </li>
            </ul>

            {/* Copyrights note */}
            <div className="text-sm text-black mr-4">
              All rights reserved. Web design by{" "}
              <a href="https://souradip.com">souradip</a>.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
