import "add-to-calendar-button/assets/css/atcb.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Countdown from "./Countdown";
import {
  FaCalendarAlt,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaTiktok,
} from "react-icons/fa";
import { GrUserWorker } from "react-icons/gr";
import { atcb_action } from "add-to-calendar-button";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";

var canvas, gl; // canvas and webgl context

var shaderScript;
var shaderSource;
var vertexShader; // Vertex shader.  Not much happens in that shader, it just creates the vertex's to be drawn on
var fragmentShader; // this shader is where the magic happens. Fragment = pixel.  Vertex = kind of like "faces" on a 3d model.
var buffer;
var program; // This is the WebGL program.  It's where we inject our javascript variables into the WebGL code.
var positionLocation; // This is the location of the position variable in the WebGL code.  We use this to tell WebGL where to draw the vertex's.

/* Variables holding the location of uniform variables in the WebGL. We use this to send info to the WebGL script. */
var locationOfTime;
var locationOfResolution;

var startTime = new Date().getTime(); // Get start time for animating
var currentTime = 0;

function init() {
  var body = document.body,
    html = document.documentElement;

  var height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  // standard canvas setup here, except get webgl context
  canvas = document.getElementById("glscreen");
  gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  canvas.width = window.innerWidth;
  canvas.height = height;

  // give WebGL it's viewport
  gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);

  // kind of back-end stuff
  buffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([
      -1.0, -1.0, 1.0, -1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0, 1.0,
    ]),
    gl.STATIC_DRAW
  ); // ^^ That up there sets up the vertex's used to draw onto. I think at least, I haven't payed much attention to vertex's yet, for all I know I'm wrong.

  shaderScript = document.getElementById("2d-vertex-shader");
  shaderSource = shaderScript.text;
  vertexShader = gl.createShader(gl.VERTEX_SHADER); //create the vertex shader from script
  gl.shaderSource(vertexShader, shaderSource);
  gl.compileShader(vertexShader);

  shaderScript = document.getElementById("2d-fragment-shader");
  shaderSource = shaderScript.text;
  fragmentShader = gl.createShader(gl.FRAGMENT_SHADER); //create the fragment from script
  gl.shaderSource(fragmentShader, shaderSource);
  gl.compileShader(fragmentShader);

  program = gl.createProgram(); // create the WebGL program.  This variable will be used to inject our javascript variables into the program.
  gl.attachShader(program, vertexShader); // add the shaders to the program
  gl.attachShader(program, fragmentShader); // ^^
  gl.linkProgram(program); // Tell our WebGL application to use the program
  gl.useProgram(program); // ^^ yep, but now literally use it.

  /* 
	
	Alright, so here we're attatching javascript variables to the WebGL code.  First we get the location of the uniform variable inside the program. 
	
	We use the gl.getUniformLocation function to do this, and pass thru the program variable we created above, as well as the name of the uniform variable in our shader.
	
	*/
  locationOfResolution = gl.getUniformLocation(program, "u_resolution");
  locationOfTime = gl.getUniformLocation(program, "u_time");

  /*
	
	Then we simply apply our javascript variables to the program. 
	Notice, it gets a bit tricky doing this.  If you're editing a float value, gl.uniformf works. 
	
	But if we want to send over an array of floats, for example, we'd use gl.uniform2f.  We're specifying that we are sending 2 floats at the end.  
	
	You can also send it over to the program as a vector, by using gl.uniform2fv.
	To read up on all of the different gl.uniform** stuff, to send any variable you want, I'd recommend using the table (found on this site, but you need to scroll down about 300px) 
	
	https://webglfundamentals.org/webgl/lessons/webgl-shaders-and-glsl.html#uniforms
	
	*/
  gl.uniform2f(locationOfResolution, canvas.width, canvas.height);
  gl.uniform1f(locationOfTime, currentTime);

  render();
}

function render() {
  var now = new Date().getTime();
  currentTime = (now - startTime) / 1000; // update the current time for animations

  gl.uniform1f(locationOfTime, currentTime); // update the time uniform in our shader

  window.requestAnimationFrame(render, canvas); // request the next frame

  positionLocation = gl.getAttribLocation(program, "a_position"); // do stuff for those vertex's
  gl.enableVertexAttribArray(positionLocation);
  gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);
  gl.drawArrays(gl.TRIANGLES, 0, 6);
}

function App() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    window.addEventListener("load", function (event) {
      setTimeout(init, 500);
    });

    window.addEventListener("resize", function (event) {
      // just re-doing some stuff in the init here, to enable resizing.
      var body = document.body,
        html = document.documentElement;

      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      canvas.width = window.innerWidth;
      canvas.height = height;
      gl.viewport(0, 0, window.innerWidth, height);
      locationOfResolution = gl.getUniformLocation(program, "u_resolution");
    });
  });

  return (
    <>
      <canvas id="glscreen"></canvas>
      <div className="App">
        {/*div className="social">
          <a href="mailto:presidents.emb84@gmail.com">
            <FaEnvelope />
          </a>
          {/* <a href="https://www.facebook.com/emmanuelmayball">
            <FaFacebookF />
          </a>
          <a href="https://www.instagram.com/emmanuelmayball/">
            <FaInstagram />
          </a>
          {/*<a href="https://www.tiktok.com/@emmamayweek">
            <FaTiktok />
          </a>
        </div>*/}
        <header className="App-header">
          <p>
            <div style={{ fontSize: "100%" }}>EMMANUEL JUNE EVENT</div>
            <div style={{ fontSize: "50%", marginTop: 0, marginBottom: 20 }}>
              EVENT DATE: 22ND JUNE 2025
            </div>
            {/* <div style={{fontSize: "60%"}}> COUNTDOWN TO LAUNCH:</div> */}
          </p>
          <div className="countdown">
            <div className="actualCount">
              <Countdown />
            </div>
            <br />
            UNTIL LAUNCH
            <br />
            <br />
            (19TH FEB 2025 22:00hrs)
          </div>
        </header>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Worker applications</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Woohoo, we're really excited you want to work with us! Please choose
            the type of work you would like to do below:
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                window.location.href =
                  "https://l.facebook.com/l.php?u=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSfe4gP-dkKcS4Ts3DX_4KcjzvNMoVji7ksforFriSK4F55zNA%2Fviewform%3Fusp%3Dsf_link%26fbclid%3DIwAR2OBu5pkIjj_S0UEYoxeXtBf_cRfJzmr0mMgy1nJNA6yeqUVAb62zCTXvw&h=AT3G6ZX88e0lZq4-fkqFZTanv06XZlbb1qdnvTZdSMs0CptEw1oD9aVZ97AQ0EkriPF-Yy0pG6ylkgLKIr8ov7Mfwmk6HWsEJN7R6J4z8XhV2ePWByMs4r2XzZQuNdjjmQM";
              }}
            >
              Half on/Half off
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                window.location.href =
                  "https://l.facebook.com/l.php?u=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSeJMcFvDa28oREOAUNbjbXme37mSF1l1yQB4x430v-2MZP5ww%2Fviewform%3Fusp%3Dsf_link%26fbclid%3DIwAR1XZkoQhmJULOs6EtXek_wAG0O_f_4i8N5Z39G4jPT6bUgCTpQ2ZY1B2aI&h=AT3G6ZX88e0lZq4-fkqFZTanv06XZlbb1qdnvTZdSMs0CptEw1oD9aVZ97AQ0EkriPF-Yy0pG6ylkgLKIr8ov7Mfwmk6HWsEJN7R6J4z8XhV2ePWByMs4r2XzZQuNdjjmQM";
              }}
            >
              Supervisor role
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default App;
