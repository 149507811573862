import React, { useEffect } from "react";
import Header from "./Header";

import "add-to-calendar-button/assets/css/atcb.css";

import { FaEnvelope, FaTiktok } from "react-icons/fa";

import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./Footer";

// function component
const Faq = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <Header always={true} />
      <div
        className="calbut"
        style={{
          marginTop: isMobile ? -162 : 0,
          maxWidth: "800px",
          background: "#FDE2AB",
          padding: 30,
          borderRadius: 20,
          margin: "auto",
          border: "none",
          boxShadow: "none",
          background: "black",
        }}
      >
        <div
          className="max-w-3xl mx-auto text-center pb-12 md:pb-16"
          id="headerr faq"
          style={{
            marginTop: "220px",
            background: "black",
            color: "white",
          }}
        >
          <h1 className="h2 mt-12 mb-4">Frequently Asked Questions</h1>
          <h1 className="h3 mb-4">When is Emma June Event?</h1>
          <p className="text-xl text-white">
            Emma June Event will be hosted on the 22nd of June 2025.
          </p>
          <h1 className="h3 mb-4">How many guest tickets can I purchase?</h1>
          <p className="text-xl text-white">
            Current students at Emmanuel are allowed to purchase up to four
            guest tickets (not including themselves). Alumni are allowed to
            purchase up to three guest tickets. Other current students of the
            University are allowed to purchase up to three guest tickets. These
            guests are not required to be members of the University.
          </p>
          <h1 className="h3 mb-4">What is the dress code?</h1>
          <>
            <p className="text-xl text-white">
              The dress code is Black Tie and formal national dress.{" "}
            </p>
          </>

          <h1 className="h3 mb-4">How Can I Buy a Bursary Ticket?</h1>
          <p className="text-xl text-white">
            We have 100 non-emmanuel student bursary tickets this year, however
            before purchase of a bursary ticket can be made, confirmation of
            bursary status must be provided. This is via a form, which once
            filled will provide you with a unique login code for the ticketing
            website. You can access the form{" "}
            <a className="underline" href="https://forms.gle/hw6Lr1DCoethyzCN8">
              here
            </a>{" "}
            from midday on 22/02, and it will close once capacity is reached.
          </p>

          <h1 className="h3 mb-4">Can I change the name on my ticket?</h1>
          <p className="text-xl text-white">
            Yes, although there will be a name change fee of £15. This is
            available on the ticketing system.
          </p>

          <h1 className="h3 mb-4">
            Where can I read the terms and conditions?
          </h1>
          <p className="text-xl text-white">
            The terms and conditions are available{" "}
            <a
              href="/2025-terms.pdf"
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              here
            </a>
          </p>

          <h1 className="h3 mb-4">
            What accessibility measures will be in place?
          </h1>
          <p className="text-xl text-white">
            Read our preliminary access statement{" "}
            <a
              href="https://docs.google.com/document/d/1MWk32WmMDItO9XuQYk71iVwBNSOypUwb_Nj4AYwz7u0/edit?usp=sharing"
              target="_blank"
              style={{ textDecoration: "underline" }}
              rel="noreferrer"
            >
              here
            </a>
            .<br />
            For any questions, please email our accessibility officer, at{" "}
            <a href="mailto:access.emb84@gmail.com">access.emb84@gmail.com</a>.
          </p>

          <h1 className="h3 mb-4">What time does the event run to?</h1>
          <p className="text-xl text-white">
            The event will run until 3:30 AM, at which point a survivors’ photo
            will be taken of all the guests who have made it until the end.
          </p>

          <h1 className="h3 mb-4">
            Will I receive a refund if the ball is cancelled?
          </h1>
          <p className="text-xl text-white">
            Please see our ticketing policy in the terms and conditions linked
            above for more information.
          </p>
        </div>

        <br />
        <br />
      </div>

      <Footer />
    </div>
  );
};

export default Faq;
