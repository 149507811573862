import { css } from "@emotion/css";
import { useEffect, useState } from "react";

import AOS from "aos";
import { Modal, Button } from "react-bootstrap";
import Header from "./Header";
import ReleaseTimeline from "./ReleaseTimeline";
import TicketTypes from "./TicketTypes";
import Committee from "./Committee";
import Zoom from "react-medium-image-zoom";
import "aos/dist/aos.css";
import "react-medium-image-zoom/dist/styles.css";
import { FaEnvelope, FaTiktok } from "react-icons/fa";
import Footer from "./Footer";
import "./Home.scss";

const NewHome = () => {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  useEffect(() => {
    AOS.init();
    const cardAnimation = window.anime({
      targets: "#cardLeft",
      rotate: [-15, -30],
      rotateY: [-15, 270],
      translateX: [0, -500],
      elasticity: 200,
      easing: "easeOutCubic",
      autoplay: false,
      opacity: [1, 0],
    });
    const cardAnimation2 = window.anime({
      targets: "#cardRight",
      rotate: [15, 30],
      rotateY: [15, -270],
      translateX: [0, 500],
      elasticity: 200,
      easing: "easeOutCubic",
      autoplay: false,
      opacity: [1, 0],
    });
    const fadeonScroll = window.anime({
      targets: ".mid",
      opacity: [1, 0],
      easing: "easeOutCubic",
      autoplay: false,
    });

    const scrollPercent = () => {
      const bodyST = document.body.scrollTop;
      const docST = document.documentElement.scrollTop;
      const docSH = document.getElementById("hero").scrollHeight + 700;
      const docCH = document.documentElement.clientHeight;

      return ((docST + bodyST) / (docSH - docCH)) * 100;
    };

    /**
     * Add a scroll listener on the window object to
     * control animations based on scroll percentage.
     */
    window.onscroll = () => {
      cardAnimation.seek((scrollPercent() / 100) * cardAnimation.duration);
      cardAnimation2.seek((scrollPercent() / 100) * cardAnimation2.duration);
      fadeonScroll.seek((scrollPercent() / 100) * fadeonScroll.duration * 2);
    };
  }, []);

  return (
    <>
      <Header />
      <div id="top" className="mt-44"></div>
      <div
        id="hero"
        className={`container mx-auto flex ${css`
          height: 1200px;
        `}`}
      >
        <div
          id="cardLeft"
          className={css`
            position: sticky;
            top: 200px;
            width: 70vw;
            height: 70vw;
            max-width: 400px;
            max-height: 560px;
            background: url(/2025/card1.png);
            background-size: cover;
            border-radius: 20px;
            transform: rotate(-15deg);
          `}
        ></div>
        <div className={`mid flex-1 text-center flex items-center flex-col`}>
          <div
            className={`title ${css`
              font-size: 2rem;
              @media (max-width: 640px) {
                margin-top: -100px;
              }
            `}`}
          >
            The Emmanuel College June Event Committee Presents...
          </div>
          <div
            className={`scroll ${css`
              margin-top: 300px;
              @media (max-width: 640px) {
                margin-top: 60vh;
              }
            `}`}
          ></div>
        </div>
        <div
          id="cardRight"
          className={css`
            position: sticky;
            top: 200px;
            width: 70vw;
            height: 70vw;
            max-width: 400px;
            max-height: 560px;
            background: url(/2025/card2.png);
            background-size: cover;
            border-radius: 20px;
            transform: rotate(15deg);
          `}
        ></div>
      </div>
      <div
        id="about"
        className={`container mx-auto ${css`
          margin-top: -200px;
        `}`}
        data-aos="fade-up"
      >
        <div>
          <div
            id="vidcont"
            className="relative flex justify-center mb-8"
            style={{ zIndex: 3, position: "relative" }}
          >
            <div className="flex flex-col justify-center">
              <img
                className="mx-auto"
                src="/2025/vcover.jpeg"
                width="768"
                height="432"
                alt="Hero"
                style={{ width: "100vw", objectFit: "cover", height: "500px" }}
              />
            </div>
            <button
              className="absolute top-full flex items-center transform -translate-y-1/2 hover:text-yellow-600 bg-gray-100 rounded-full font-medium group p-4 shadow-lg text-black"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setVideoModalOpen(true);
              }}
              aria-controls="modal"
            >
              <svg
                className="w-6 h-6 fill-current text-gray-900 group-hover:text-yellow-600 flex-shrink-0"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                <path d="M10 17l6-5-6-5z" />
              </svg>
              <span className="ml-3">
                Watch the full promotional video for Emma June Event (3 min)
              </span>
            </button>
          </div>

          <Modal
            id="modal"
            ariaLabel="modal-headline"
            show={videoModalOpen}
            handleClose={() => setVideoModalOpen(false)}
          >
            <div className="relative pb-9/16">
              <iframe
                className="absolute w-full h-full"
                src={process.env.REACT_APP_VIDEO}
                title="Video"
                allowFullScreen
              ></iframe>
            </div>

            <Modal.Footer>
              <Button
                variant=""
                onClick={() => {
                  setVideoModalOpen(false);
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <br />
        <br />
      </div>

      <div className="mt-20" data-aos="fade-down">
        <section class="overflow-hidden text-white" data-aos="fade-right">
          <div class="container px-5 py-2 mx-auto lg:pt-24 lg:px-32">
            <h1 className="h2 mb-8">Gallery</h1>
            <div class="flex flex-wrap -m-1 md:-m-2">
              <div class="flex flex-wrap w-1/2">
                <div class="w-1/2 p-1 md:p-2" data-aos="fade-right">
                  <Zoom>
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/2025/3.jpg"
                    />
                  </Zoom>
                </div>

                <div
                  class="w-1/2 p-1 md:p-2"
                  data-aos="fade-right"
                  data-aos-delay="150"
                >
                  <Zoom>
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/2025/4.jpg"
                    />
                  </Zoom>
                </div>
                <div
                  class="w-full p-1 md:p-2"
                  data-aos="fade-right"
                  data-aos-delay="250"
                  src="/2025/1.jpg"
                >
                  <Zoom>
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/2025/1.jpg"
                    />
                  </Zoom>
                </div>
              </div>
              <div class="flex flex-wrap w-1/2">
                <div
                  class="w-full p-1 md:p-2"
                  data-aos="fade-right"
                  data-aos-delay="350"
                >
                  <Zoom>
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/2025/2.jpg"
                    />
                  </Zoom>
                </div>
                <div
                  class="w-1/2 p-1 md:p-2"
                  data-aos="fade-right"
                  data-aos-delay="450"
                >
                  <Zoom>
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/2025/6.jpg"
                    />
                  </Zoom>
                </div>
                <div
                  class="w-1/2 p-1 md:p-2"
                  data-aos="fade-right"
                  data-aos-delay="550"
                >
                  <Zoom>
                    <img
                      alt="gallery"
                      class="block object-cover object-center w-full h-full rounded-lg"
                      src="/2025/5.jpg"
                    />
                  </Zoom>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <ReleaseTimeline />

      <TicketTypes />

      <Committee />
      <Footer />
    </>
  );
};

export default NewHome;
