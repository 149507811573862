import React from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "./Homepage";
import Faq from "./Faq";
//import "./Home.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NewHome from "./NewHome";

// return function component
const Home = () => {
  const router = createBrowserRouter([
    {
      path: "/faq",
      element: (
        <>
          <Faq />
        </>
      ),
    },
    {
      path: "/",
      element: (
        <Routes>
          <Route exact path="/" element={<NewHome />} />
        </Routes>
      ),
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};
export default Home;
