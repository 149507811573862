import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "antd";

import Logo from "./logo.png";
import "./Home.scss";
import { css } from "@emotion/css";

function Header({ always }) {
  const [top, setTop] = useState(true);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  return (
    <header
      className={`fixed w-full top-0 z-30 md:bg-opacity-90 transition duration-300 coll ease-in-out ${
        !top && "shadow-lg bg-orange ncoll visible"
      } ${
        top
          ? css`
              opacity: 0;
            `
          : css`
              opacity: 1;
            `
      } ${
        always &&
        css`
          opacity: 1;
        `
      }`}
    >
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div
            className="flex-shrink-0 mr-4 block hifsmall"
            style={{ marginLeft: "-16px" }}
          >
            {/* Logo */}
            <Link
              to="/"
              className={`block title ${css`
                font-size: 2.5rem;
              `}`}
            >
              Courting Fate
            </Link>
          </div>

          {/* Site navigation */}
          <nav className="flex flex-grow">
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              {/*<li>
                <a href="javascript:void" onClick={showModal} className=" mr-3  ">
                  <span>Workers</span>              
                </a>
              </li>*/}
              <li>
                <a href="/#committee" className=" mr-3">
                  <span>Contact</span>
                </a>
              </li>
              <li>
                <Link to="/faq" className="mr-3 ">
                  <span>FAQs</span>
                </Link>
              </li>
              <li>
                <a href="/2024-access.pdf" target="_blank" className="mr-3 ">
                  <span>Access</span>
                </a>
              </li>
              <li>
                <a href="/2025-terms.pdf" target="_blank" className="mr-3 ">
                  <span>Terms</span>
                </a>
              </li>
              <li>
                <a
                  href="https://tickets.emmamayball.co.uk"
                  className="btn-sm tickbutton ml-3 text-white  "
                >
                  <span>Tickets</span>
                  <svg
                    className="w-3 h-3 fill-current text-gray-100 flex-shrink-0 ml-2 -mr-1"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                      fillRule="nonzero"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <Modal
        open={open}
        title="Worker Applications"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="link"
            href="https://docs.google.com/forms/d/e/1FAIpQLSfe4gP-dkKcS4Ts3DX_4KcjzvNMoVji7ksforFriSK4F55zNA/viewform"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Half on/half off
          </Button>,
          <Button
            key="link"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeJMcFvDa28oREOAUNbjbXme37mSF1l1yQB4x430v-2MZP5ww/viewform"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Supervisor
          </Button>,
        ]}
      >
        <p>
          Woohoo, we're really excited you want to work with us! Please choose
          the type of work you would like to do below:
        </p>
      </Modal>
    </header>
  );
}

export default Header;
